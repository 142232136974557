import { Dialog, DialogContent } from '@material-ui/core'
import React from 'react'
import logo from '../assets/img/belroy_black.png';

const OrderDialog = (props) => {

    const { order, open, handleClose } = props;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
            <DialogContent className="flex flex-col jusitfy-center">
                <img src={logo} alt='Logo Den Baba' className='h-24 w-32 mx-auto' />
                <span className='text-sm text-center my-4 item'>
                    <p>Belroysnacks</p>
                    <p>Gentsesteenweg 285</p>
                    <p>1730, Asse</p>
                    <p>0483 81 96 54</p>
                </span>
                {order && (
                    <span className='text-left mb-4'>
                        <p>{`${order.firstName} ${order.lastName}`}</p>
                        <span>
                            <p>{`${order.street} ${order.number} ${order.bus ? order.bus : ''}`}</p>
                            <p>{`${order.postalCode}, ${order.city}`}</p>
                        </span>
                        <p>{order.telephone}</p>
                    </span>
                )}
                {order && (

                    order.isAsap ?
                        <p className="my-4">
                            {"Levering "}
                            <span className="text-lg font-bold">Zo snel mogelijk</span>
                        </p>
                        :
                        <p className="my-4">
                            {"Leveren om "}
                            <span className="text-lg font-bold">{order.etd}</span>
                        </p>
                )}
                {order &&
                    order.products.map((it) => (
                        <div className='flex flex-row' key={it.id}>
                            <p className={`flex-grow-0 mr-4`}>
                                {it.meta.qty} x
                            </p>
                            <span className='flex flex-col flex-grow'>
                                <p className="font-bold">{it.name}</p>
                                <span className="ml-4 font-thin text-sm">
                                    <p className="font-thin">{it.meta.ingredients}</p>
                                    <p className="font-thin">{it.meta.extras}</p>
                                </span>
                            </span>

                            <p className='flex-grow-0'>
                                {((it.meta.price * it.meta.qty) / 100).toFixed(2).replace('.', ',')}{' '}
                &euro;
              </p>
                        </div>
                    ))}
                <hr className='my-2' />
                <span className='text-right font-bold'>
                    {order ? (
                        (order.totalPrice / 100).toFixed(2).replace('.', ',')
                    ) : (
                            ''
                        )}
                </span>

                <p>{order && order.comment}</p>

                <p className='text-center text-sm font-thin'>Bedankt en smakelijk.</p>
            </DialogContent>
        </Dialog>
    )
}

export default OrderDialog
