import React, { useEffect, useRef, useState } from 'react'
import UIfx from 'uifx';
import AlertAudio from '../assets/sounds/alarm.wav';

import * as moment from "moment";
import {
    Truck,
    Printer,
    Eye,
    Settings,
} from "react-feather";

import { IconButton } from "@material-ui/core";
import logo from '../assets/img/belroy_white.png';
import Receipt from './Receipt';
import OrderDialog from './OrderDialog';
import { Alert } from '@material-ui/lab';

const Home = () => {

    const alertSound = new UIfx(AlertAudio, { volume: 1 })

    const [playSound, setPlaySound] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [newOrders, _setNewOrders] = useState([]);
    const [refreshOrders, _setRefreshOrders] = useState(false);
    const [selectedOrder, _setSelectedOrder] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const selectedOrderRef = useRef(selectedOrder);
    const newOrdersRef = useRef(newOrders);
    const refreshOrdersRef = useRef(refreshOrders);

    const setSelectedOrder = data => {
        selectedOrderRef.current = data;
        _setSelectedOrder(data);
    }

    const setNewOrders = data => {
        newOrdersRef.current = data;
        _setNewOrders(data);
    }

    const setRefreshOrders = data => {
        refreshOrdersRef.current = data;
        _setRefreshOrders(data);
    }

    useEffect(() => {
        fetch('https://belroysnacks.be/api/ordersToday').then(res => res.json()).then(res => setOrders(res));
    }, [refreshOrders])

    useEffect(() => {
        const openHour = moment("15:00", "HH:mm");
        const closeHour = moment("22:00", "HH:mm");

        const timer = setInterval(() => {
            const now = moment();

            if (now > openHour && now < closeHour) {
                console.log('Able to fetch data');
                fetch('https://belroysnacks.be/api/newOrders')
                    .then(res => res.json())
                    .then(res => setNewOrders(res));
            }

        }, (1000 * 60));

        return () => clearInterval(timer);
    })

    useEffect(() => {
        let timer;
        if (newOrders.length) {
            setSelectedOrder(newOrders[0]);
            setPlaySound(true);
            timer = setTimeout(() => {
                window.print();
            }, 500);
        } else {
            setSelectedOrder(null);
        }

        return () => clearTimeout(timer);
    }, [newOrders])

    useEffect(() => {
        const handleAfterPrint = () => {
            if (selectedOrderRef.current && !selectedOrderRef.current.isAccepted) {

                fetch('https://belroysnacks.be/api/acceptOrder', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ id: selectedOrderRef.current.id })
                })
                    .then(res => res.json())
                    .then(res => {
                        console.log(res);
                        setNewOrders(newOrdersRef.current.filter(no => no.id !== selectedOrderRef.current.id));
                        setRefreshOrders(!refreshOrdersRef.current);
                    });
            }
        }

        window.addEventListener('afterprint', handleAfterPrint);

        return () => window.removeEventListener('afterprint', handleAfterPrint);
    }, [])

    useEffect(() => {
        if (playSound) {
            alertSound.play(1);
            setPlaySound(false);
        }
    }, [playSound])

    return (
        <>
            { newOrders.length > 1 &&
                <Alert severity="info">Nieuwe bestelling, de bestelling wordt onmiddellijk afgeprint.</Alert>
            }

            {selectedOrder && <Receipt order={selectedOrder} />}
            <div className="flex-grow p-4 select-none print:hidden">
                {selectedOrder &&
                    <OrderDialog
                        className="w-full"
                        open={openDialog}
                        handleClose={() => setOpenDialog(false)}
                        order={selectedOrder}
                    />}
                <div className="flex flex-row justify-between items-center">
                    <img src={logo} alt="Logo belroysnacks" className="w-auto h-20" />
                </div>
                <div className="flex flex-col flex-wrap justify-center">
                    <span className="flex flex-row justify-between items-center">
                        <p className="uppercase text-3xl text-white font-thin my-4">
                            Bestellingen{" "}
                            <span className="text-sm font-hairline">
                                {moment().toString()}
                            </span>
                        </p>
                    </span>

                    <div className="flex flex-row justify-around my-4">
                        <div className="text-white bg-teal-500 rounded p-4 shadow-lg w-1/6">
                            <p className="font-black text-3xl text-right">
                                {(orders.reduce((acc, curr) => acc + curr.totalPrice, 0) / 100)
                                    .toFixed(2)
                                    .replace(".", ",")}
                &euro;
              </p>
                            <p className="text-right text-sm font-thin">Totaal</p>
                        </div>
                        <div className="text-white bg-teal-500 rounded p-4 shadow-lg w-1/6">
                            <p className="font-black text-3xl text-right">
                                {orders.length}
                            </p>
                            <p className="text-right text-sm font-thin">Leveringen</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                        {!loading ? (
                            orders.map((o) => (
                                <div className="w-full mr-4 select-none" key={o.id}>
                                    <div className="lg:w-48 flex-none bg-cover rounded text-center overflow-hidden"></div>
                                    <div
                                        className={`border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal h-64 ${o.status === "new" && "bg-red-300"
                                            }`}
                                    >
                                        <div className="mb-8">
                                            <div className="text-gray-600 flex items-center justify-between">
                                                <p className="font-bold text-xl">{o.isAsap ? "ZSM" : o.etd}</p>
                                                <Truck />
                                            </div>
                                            <div className="text-gray-900 font-bold text-xl mb-2">
                                                <p>{`${o.firstName} ${o.lastName}`}</p>
                                            </div>

                                            <p className="text-gray-700 text-base">
                                                {`${o.street} ${o.number} ${o.bus ? o.bus : ""}`}
                                            </p>
                                            <p className="text-gray-700 text-base">{`${o.postalCode} ${o.city}`}</p>


                                        </div>
                                        <div className="flex flex-row justify-between items-center text-gray-600">
                                            <p className="text-gray-600 ml-4">&euro;</p>

                                            <IconButton
                                                size="medium"
                                                className="focus:outline-none"
                                                onClick={() => {
                                                    setSelectedOrder(o);
                                                    setOpenDialog(true);
                                                }}
                                            >
                                                <Eye />
                                            </IconButton>
                                            <IconButton
                                                size="medium"
                                                className="focus:outline-none"
                                                onClick={() => {
                                                    setSelectedOrder(o);
                                                    setTimeout(() => {
                                                        window.print();
                                                    }, 500);
                                                }}
                                            >
                                                <Printer />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                                <p>Loading</p>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
