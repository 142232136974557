import React from 'react'

import BelroyLogo from '../assets/img/belroy_black.png';

const Receipt = (props) => {

    const { order } = props;

    return (
        <div className="hidden print:block flex flex-col flex-grow bg-white text-sm text-black">
            <img src={BelroyLogo} alt="Logo Belroy" className="h-24 w-32 mx-auto" />
            <span className="text-xs text-center my-4 item">
                <p>Belroysnacks</p>
                <p>Gentsesteenweg 285</p>
                <p>1730, Asse</p>
                <p>0483 81 96 54</p>
            </span>
            <span className="text-left font-bold mb-4">
                {order &&
                    <>
                        <p>{`${order.firstName} ${order.lastName}`}</p>
                        <span>
                            <p>{`${order.street} ${order.number} ${order.bus ? order.bus : ''}`}</p>
                            <p>{`${order.postalCode}, ${order.city}`}</p>
                        </span>
                        <p>{order.telephone}</p>
                    </>}
                {order && (
                    order.isAsap ?
                        <p className="my-4">
                            {`Levering: `}
                            <span className="text-lg font-bold">Zo snel mogelijk</span>
                        </p>
                        :
                        <p className="my-4">
                            {"Leveren om "}
                            <span className="text-lg font-bold">{order.time}</span>
                        </p>
                )}
                {order && order.products.length &&
                    order.products.map((it) => (
                        <div className="flex flex-row justify-between" key={it.id}>
                            <p className={`flex-grow-0 w-8 text-right mr-4 ${it.meta.qty > 1 && "font-bold"}`}>
                                {it.meta.qty} x
                            </p>
                            <span className="flex flex-col flex-grow">
                                <p className="font-bold">
                                    {it.name}
                                </p>
                                <span className="ml-4 text-sm font-thin">
                                    <p>{it.meta.ingredients}</p>
                                </span>
                                <span className="ml-4 text-sm font-thin">
                                    <p>{it.meta.extras}</p>
                                </span>
                            </span>
                            <p className="flex-grow-0 text-right">
                                {((it.meta.price * it.meta.qty) / 100).toFixed(2).replace(".", ",")} &euro;
                            </p>
                        </div>
                    ))}
            </span>
            <span className="my-4 border-t-2 border-black">
                {order &&
                    <span className="flex flex-row justify-between">
                        <p className="text-right">TE BETALEN</p>
                        <p className="text-right font-bold">
                            {(order.totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
                            </p>
                    </span>}
            </span>
            <span>
                <p className="mb-4 font-bold">{order && order.comment}</p>
                <p className="text-center text-sm">Bedankt en smakelijk!</p>
            </span>
        </div>
    )
}

export default Receipt
