import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import Home from './components/Home';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0d47a1"
    },
    secondary: {
      main: '#42ad8d',
      light: "#117374"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
